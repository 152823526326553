<template>
  <div class="security-info">
    <img
        src="../../../assets/images/Amblem2@2x.webp"
        width="32"
        height="38"
        alt="Glocal logo"
    />
    <div class="heading-box">
      <h1 class="heading-box__heading">
        Sign up to Glocal and experience the News Intelligence™ environment
      </h1>
      <div class="heading-box__progress">
        <span class="progress-line progress-line--1"/>
        <span class="progress-line progress-line--2"/>
        <span class="progress-line progress-line--3"/>
      </div>
    </div>
    <h6 class="security-info__heading">Security Information</h6>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="password">Create Password</label>
        <a-input-password
            required
            type="password"
            id="password"
            placeholder="Enter Password"
            v-model:value="password"
        />
      </div>
      <div class="form-group">
        <label for="confirm-password">Confirm Password</label>
        <a-input-password
            type="password"
            id="confirm-password"
            placeholder="Re-Enter Password"
            v-model:value="confirmPassword"
        />
      </div>
      <p class="error" v-for="err in error" :key="err">
        {{ err }}
      </p>
      <div class="buttons">
        <button
            type="primary"
            class="btn back-btn"
            @click="$emit('next', 'presonal')"
        >
          Go Back
        </button>
        <a-button
            htmlType="submit"
            :loading="loading"
            class="btn btn-primary continue-btn-primary"
        >
          Save &amp; Continue
        </a-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      confirmPassword: "",
      error: [],
      loading: false,
    };
  },
  methods: {
    async submitForm() {
      try {
        this.loading = true;
        this.error = [];
        if (this.password === this.confirmPassword) {
          const payload = {
            password: this.password,
            confirmPassword: this.confirmPassword,
          };
          const response = await this.$store.dispatch(
              "auth/setPasswordDetail",
              payload
          );
          if (response.status === 200) {
            this.$emit("next", "interest");
          }
        } else {
          this.error.push("Password and Confirm Password does not match");
        }
      } catch (error) {
        this.error.push(...error.response.data.password);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@include reset-css;
.security-info {
  padding: 4.8rem 6.1rem 0 6rem;

  .heading-box {
    padding-top: 2.1rem;
    display: flex;
    justify-content: space-between;

    &__heading {
      font-size: 2rem;
      font-family: "Roboto-bold";
      width: 33rem;
      margin: 0;

      @include respond(laptop-small) {
        width: 60%;
      }
    }

    .progress-line {
      width: 3.7rem;
      height: 0.4rem;
      display: inline-block;

      &--1,
      &--2 {
        background-color: $color-primary;
      }

      &--3 {
        background-color: #c8c8c8;
      }
    }

    .progress-line:not(:last-child) {
      margin-right: 0.7rem;
    }
  }

  &__heading {
    font-size: 1.6rem;
    font-family: $font-secondary-bold;
    padding: 2.5rem 0 1.1rem 0;
  }

  &__heading--verification {
    padding-bottom: 0;
    margin: 0;
  }

  .form-group {
    display: flex;
    flex-direction: column;

    label {
      font-size: 1.6rem;
      font-family: $font-secondary;
      margin: 1.4rem 0;
    }

    .ant-input-password {
      width: 40.07rem;
      border: none;
      background-color: #f6f8ff;
      padding: 0 2rem;

      @include respond(laptop-small) {
        width: 100%;
      }

      .ant-input {
        background-color: #f6f8ff !important;
        height: 3.9rem;
      }

      &::-webkit-input-placeholder {
        font-family: $font-secondary;
        font-size: 1.6rem;
        text-indent: 2rem;
      }

      &::-moz-placeholder {
        font-family: $font-secondary;
        font-size: 1.6rem;
        text-indent: 2rem;
      }
    }
  }

  .error {
    text-align: left;
    margin-bottom: 0;
    width: 40.07rem;
    font-size: 1.4rem;
    margin-top: 0.5rem;
  }

  .buttons {
    display: flex;
    margin: 3.8rem 0;

    .btn {
      border: none;
      margin-right: 1rem;
      font-size: 1.6rem;
      font-family: $font-secondary;
      padding: 0;
      height: 4.8rem;
      width: 19rem;
    }

    .back-btn {
      background-color: #191919;
      border-radius: 0;
      border-color: transparent;
      color: $color-white;
      cursor: pointer;
    }
  }
}
</style>
