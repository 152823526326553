<template>
  <div class="side-article">
    <div class="side-article__slider">
      <div class="side-article__slider-btns">
        <button
          ref="btn-left"
          class="
            side-article__sliderbtn
            side-article__sliderbtn--left
            side-article__sliderbtn--disable
          "
          @click="changeArticle(-1)"
        >
          <i class="icon icon-forward"/>
        </button>
        <button
          ref="btn-right"
          class="side-article__sliderbtn side-article__sliderbtn--right"
          @click="changeArticle(1)"
        >
          <i class="icon icon-forward"/>
        </button>
      </div>
    </div>
    <div class="slide-box">
      <div class="container" ref="slider">
        <div
          class="slide-main"
          :key="article.id"
          v-for="article in articles"
          :style="{ backgroundImage: `url(${article.image})` }"
        >
          <div class="side-article__content">
            <h1 class="side-article__heading">{{ article.title }}</h1>
            <div class="side-article__bottom-content">
              <h2 class="side-article__subheading">{{ article.subheading }}</h2>
              <p class="side-article__para">{{ article.description }}</p>
              <p class="side-article__author">
                Posted by &nbsp; <strong>{{ article.authors }}</strong>
              </p>
              <p class="side-article__author">
                Source &nbsp; <strong>{{ article.article_url.source.toUpperCase() }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      articles: [],
      articleDisplayed: 0,
    };
  },
  async mounted() {
    this.slide = this.$refs["slider"];
    this.$refs["btn-left"].disabled = true;
    this.slide.style.transition = `1s`;
    await this.getSignupArticles();
  },
  methods: {
    changeArticle(num) {
      this.articleDisplayed += num;
      this.$refs["btn-right"].disabled = false;
      this.$refs["btn-left"].disabled = false;
      this.$refs["btn-left"].classList.remove(
        "side-article__sliderbtn--disable"
      );
      this.$refs["btn-right"].classList.remove(
        "side-article__sliderbtn--disable"
      );
      if (this.articleDisplayed === 6) {
        this.$refs["btn-right"].disabled = true;
        this.$refs["btn-right"].classList.add(
          "side-article__sliderbtn--disable"
        );
      } else if (this.articleDisplayed === 0) {
        this.$refs["btn-left"].disabled = "true";
        this.$refs["btn-left"].classList.add(
          "side-article__sliderbtn--disable"
        );
      }
      this.slide.style.transform = `translate(${
        this.articleDisplayed * -100
      }%, 0)`;
    },
    async getSignupArticles() {
      const response = await this.$store.dispatch("auth/getSignupArticles");
      if (response.status === 200) {
        this.articles = response.data;
      }
    },
  },
};
</script>

<style lang="scss">
.side-article {
  font-family: $font-secondary;
  height: 100vh;
  position: relative;
  color: $color-white;

  .slide-main {
    height: 100vh;
    min-width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__content {
    padding: 8rem 6rem 6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    min-width: 100%;
    background-color: rgba(0, 0, 0, 0.605);
  }

  &__heading {
    color: $color-white;
    font-size: 3rem;
    font-family: $font-secondary-bold;
    max-width: 47rem;
    line-height: 1.4;
  }

  &__subheading {
    font-size: 2.5rem;
    font-family: $font-secondary-bold;
    color: $color-white;
  }

  &__para {
    font-size: 2rem;
    opacity: 0.8;
    letter-spacing: 0;
    line-height: 1.4;
  }

  &__author {
    font-size: 1.6rem;
    strong {
      font-family: $font-secondary-bold;
    }
  }

  &__slider {
    position: absolute;
    top: 55%;
    left: 6rem;
    z-index: 100;

    &-btns {
      display: flex;
    }
  }

  &__sliderbtn {
    background-color: $color-primary;
    color: $color-white;
    width: 4.2rem;
    height: 4.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;

    &--left {
      transform: rotate(180deg);
      margin-right: 0.6rem;
    }

    &--disable {
      opacity: 0.5;
    }
  }

  &__sliderbtn {
    font-size: 1.3rem;
  }

  .slide-box {
    width: 100%;
    overflow: hidden;
    background-color: black;
  }

  .container {
    display: flex;
  }
}
</style>