<template>
  <div class="the-policy">
    <h2 class="heading">GLOCAL PRIVACY POLICY</h2>
    <p>
      The Glocal privacy policy is a set of internal guidelines and principles
      by which we will ensure compliance with relevant privacy legislation,
      monitor for violations, and pressure-test new features for security.
    </p>
    <ul>
      <li>
        All movement and storage of data will be captured in a company Data Map
        which will be regularly updated.
      </li>
      <li>
        The company will have a privacy email alias through which users and
        contributors may communicate directly with us to resolve any privacy
        concerns or issues.
      </li>
      <li>
        We will launch a set of privacy tools which will enable them to control
        what data is being collected and how it is used:
        <ul class="list-two">
          <li>Cookie notice with opt outs</li>
          <li>Conspicuous marketing consents</li>

          <li>Open lists of third party vendors</li>
        </ul>
      </li>
      <li>
        The company will engage in a Privacy Impact Assessment for every new
        feature added to the product and/or services to ensure that we remain
        complaint and vigilant while scaling and expanding our offerings
      </li>
      <li>
        All employees will go through a Privacy training and especially be
        informed of the risks local storage of personal data
      </li>
      <li>
        The company will enact policies that automatically scrub and/ore secure
        data on our systems (ie. deleting the company Slack messages every 30
        days, enforcing password changes every two months)
      </li>
      <li>
        We will have access to Privacy Counsel and will consult him or her when
        it is necessary or relevant
      </li>
      <li>
        As a California based company, we are aware of the California Consumer
        Protection Act (CCPA) and will ensure compliance by the required date in
        2020.
      </li>
      <li>
        We will ensure to run all individuals signed up with us as users or
        contributors are compared with the Specially Designated Individuals and
        Blocked Persons lists as well as with the current list of OFAC
        sanctioned countries.
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.the-policy {
  padding: 10rem;

  .heading {
    font-size: 5rem;
    font-family: $font-secondary;
    text-align: center;
    margin-bottom: 3rem;
    text-transform: uppercase;
  }

  p {
    font-family: $font-secondary;
    font-size: 1.8rem;
    margin-top: 1rem;
    font-style: italic;
  }

  ul {
    li {
      font-family: $font-secondary;
      font-size: 1.8rem;
      margin-left: 2rem;
      margin-bottom: 3rem;
    }
  }

  .list-two {
    list-style-type: circle;
    margin-top: 1rem;

    li {
      margin-bottom: 1rem;
    }
  }
}
</style>