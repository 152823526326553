<template>
  <a-modal>
    <div v-if="type === 'privacy'">
      <the-privacy/>
    </div>
    <div v-else>
      <the-policy/>
    </div>
  </a-modal>
</template>

<script>
import ThePrivacy from "../../Auth/pages/ThePrivacy.vue"
import ThePolicy from "../../Auth/pages/ThePolicy.vue"

export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  components: {
    ThePrivacy,
    ThePolicy
  }
}
</script>