<template>
  <a-row class="signup">
    <a-col class="auth-info" :xs="24" :sm="24" :md="12" :lg="12">
      <keep-alive>
        <component :is="activeScreen" @next="nextSection"></component>
      </keep-alive>
    </a-col>
    <a-col :span="12" class="right-article">
      <signup-article></signup-article>
    </a-col>
  </a-row>
</template>

<script>
import SignupArticle from "../components/Auth/signUpGlocal/SignupArticle.vue";
import PersonalInformation from "../components/Auth/signUpGlocal/PersonalInformation.vue";
import SecurityInformation from "../components/Auth/signUpGlocal/SecurityInformation.vue";
import InterestInformation from "../components/Auth/signUpGlocal/InterestInformation.vue";

export default {
  components: {
    SignupArticle,
    PersonalInformation,
    SecurityInformation,
    InterestInformation,
  },
  data() {
    return {
      activeScreen: null,
      emailerr: "",
      passworderr: "",
    };
  },
  mounted() {
    this.activeScreen = PersonalInformation;
  },
  methods: {
    nextSection(val) {
      this.activeScreen =
          val === "security"
              ? SecurityInformation
              : val === "interest"
                  ? InterestInformation
                  : PersonalInformation;
    },
    emailErr(err) {
      this.emailerr = err;
    },
    passwordErr(err) {
      this.passworderr = err;
    }
  },
};
</script>

<style lang="scss">
.signup {
  overflow: hidden;
}

.auth-info {
  background: linear-gradient(95deg, #ffffff 0%, #ffffff00 100%);
  height: 100vh;
  position: relative;
  overflow-y: scroll;

  ::before {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../assets/images/dotted_background.png");
    content: "";
    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
}

.right-article {
  @include respond(phone-large) {
    display: none !important;
  }
}
</style>
