<template>
  <div
    class="interest-list"
    v-for="(interest, index) in interestDomain"
    :key="index"
  >
    <a-checkbox
      :checked="checkedList.includes(interest.id)"
      @change="onCheckAllChange(index)"
      >{{ interest.interest }}</a-checkbox
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      checkedList: [],
      interestDomain: [],
    };
  },
  emits:['update-list'],
  mounted: async function () {
    await this.$store.dispatch("article/fetchInterests");
    this.interestDomain = this.$store.getters["article/getInterests"];
  },
  watch: {
    checkedList: {
      deep: true,
      handler() {
        this.$emit("update-list", this.checkedList);
      },
    },
  },
  methods: {
    onCheckAllChange(index) {
      if (!this.checkedList.includes(this.interestDomain[index].id)) {
        this.checkedList.push(this.interestDomain[index].id);
      } else {
        this.checkedList.splice(
          this.checkedList.indexOf(this.interestDomain[index].id),
          1
        );
      }
    },
  },
};
</script>
<style lang="scss">
@include reset-css;
.interest-list {
  @include respond(laptop-small) {
    width: 100%;
  }
  width: 93%;
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-extra {
    float: left !important;
  }
  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: transparent;
    border-top: 0.24rem solid rgba(92, 92, 92, 0.137);
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 1rem;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border: none;
    margin-bottom: 0.8rem;
  }
  .ant-collapse-header {
    font-size: 1.8rem;
    font-family: $font-secondary-bold;
    font-weight: 900;
    background-color: transparent !important;
    border: none;
    padding: 2rem 0 !important;
  }
  .ant-checkbox {
    .ant-checkbox-inner {
      border: 0.22rem solid rgba(128, 128, 128, 0.377);
      font-size: 1.6rem;
      font-family: $font-secondary;
    }
  }
  .ant-collapse-extra {
    margin-right: 1.3rem;
  }
  .ant-collapse {
    padding: 0 2rem;
    background-color: #eef2ffbf;
  }
  .ant-checkbox-wrapper {
    font-size: 1.6rem;
    font-family: $font-secondary;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $color-primary !important;
  }
  .ant-checkbox-checked::after {
    border: none !important;
  }
  .form-checkbox {
    padding: 2rem 1rem;
  }
}
</style>
